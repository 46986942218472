import Image from 'next/image';

import Container from '@/layout/Container';

import FrontLine from '~/images/front-line.png';
import FrontLineM from '~/images/front-line-m.png';
import HowToLines from '~/svg/Line.svg';

export default function HowToSell() {
  return (
    <div id="how-to-sell">
      <Container>
        <div className='flex flex-col gap-9'>
          <div className='flex items-center gap-2'>
            <div className="text-3xl">
              <span className=' capitalize text-white'>HOW TO</span>
              <span className='text-primary-50  capitalize'> SELL</span>
              <span className=' capitalize text-white'> SKINS</span>
            </div>
          </div>

          <div className='w-full bg-white bg-opacity-5 p-6 pt-6 lg:pt-16 xl:pt-6'>
            <div className='flex flex-col gap-20 xl:flex-row'>
              <div className='relative flex flex-col gap-4 lg:flex-row xl:flex-col'>
                <div className='absolute -top-20 left-3 hidden xl:block'>
                  <Image
                    src={FrontLine}
                    alt='frontLine'
                    className='h-[700px] w-[7px] '
                  />
                </div>

                <div className='absolute -left-5 -top-4 hidden lg:block xl:hidden'>
                  <Image
                    src={FrontLineM}
                    alt='frontLine'
                    className='h-[7px] w-[1200px] '
                  />
                </div>

                <div className='flex flex-col items-center xl:flex-row'>
                  <HowToLines className='hidden h-[33px] w-24 rotate-90 lg:block xl:rotate-0' />

                  <div className='max-w-96 bg-surface-200 relative flex h-44 w-full flex-col justify-center px-9 pb-4 pt-10 text-center'>
                    <div className='text-primary-50 absolute -top-5 left-[45%] text-9xl opacity-30'>
                      1
                    </div>
                    <div className='mb-3 text-xl xl:text-2xl capitalize text-white'>
                      Login with Steam
                    </div>

                    <div className='font-secondary font-normal text-stone-300 text-sm'>
                      Add your Steam Trade URL & Email so we can send you trade
                      offers
                    </div>
                  </div>
                </div>

                <div className='flex flex-col items-center xl:flex-row'>
                  <HowToLines className='hidden h-[33px] w-24 rotate-90 lg:block xl:rotate-0' />
                  <div className='max-w-96 bg-surface-200 relative flex h-44 w-full flex-col justify-center px-9 pb-4 pt-10 text-center'>
                    <div className='text-primary-50 absolute -top-5 left-[45%] text-9xl opacity-30'>
                      2
                    </div>
                    <div className='mb-3 text-xl xl:text-2xl  capitalize text-white'>
                      Select Skins to Sell
                    </div>

                    <div className='font-secondary font-normal text-stone-300 text-sm'>
                      Choose the skins you want to sell in your preferred payout
                      method
                    </div>
                  </div>
                </div>

                <div className='flex flex-col items-center xl:flex-row'>
                  <HowToLines className='hidden h-[33px] w-24 rotate-90 lg:block xl:rotate-0' />
                  <div className='max-w-96 bg-surface-200 relative flex h-44 w-full flex-col justify-center px-9 pb-4 pt-10 text-center'>
                    <div className='text-primary-50 absolute -top-5 left-[45%] text-9xl opacity-30'>
                      3
                    </div>
                    <div className='mb-3 text-xl xl:text-2xl  capitalize text-white'>
                      Get your money
                    </div>

                    <div className='font-secondary font-normal text-stone-300 text-sm'>
                      Accept the trade offer and wait for your cash to arrive
                      via your selected payout method
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-[558px] w-full">
                <img src="/images/how-to-sell.png" alt="how-to-sell" className="h-full w-full object-contain" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
