import { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { cn } from '@/lib/utils';

import Container from '@/layout/Container';

export default function FAQ() {
  const [active, setActive] = useState(-1);
  const items = [
    {
      heading: 'What are skins?',
      text: 'Skins are in-game (CS:GO, Rust, Dota 2) items that allow the player to change their appearance in-game, either via weapon, character, gloves, etc. 1000’s of players every day buy and sell these skins at various prices, depending on rarity, popularity, and other features.',
    },
    {
      heading: 'Why do your prices differ from those on Steam Market?',
      text: 'Due to the nature of the skin economy – Steam balance is considered less worth than real money.',
    },
    {
      heading: 'Are there any fees or commissions?',
      text: 'Mainly transaction fees, depend on the cashout method. We’ll make sure to point it out – there are no hidden fees, and everything will be displayed in one place.',
    },
    {
      heading: 'What payment methods are available?',
      text: 'PayPal, Cryptocurrency (Bitcoin, ETH, USDT, etc.), Giftcards (Amazon, Spotify, PlayStation, etc.), MANY MORE TO COME!',
    },
    {
      heading: 'Why are some of my skins not accepted?',
      text: 'Few reasons: Too low of a value, The price of the skin is unstable or not liquid enough, It might be due to the fact that the skin is still on a 7-day trade lock.',
    },
    {
      heading: 'How fast do I need to wait for the payment?',
      text: 'Most payments are processed automatically. The arrival of the funds depends on the withdrawal method. On average you can expect your payment to be within 20 minutes. Crypto should be the fastest (depending on cryptocurrency and network congestion) If you haven’t received funds within 24 hours, please contact our support team.',
    },
    {
      heading: 'I didn’t find an answer to my question. How can I contact you?',
      text: 'Please reach out to our support team and we will try to find a solution. You can reach us via social media like X/Twitter, Discord or E-Mail.',
    },
    {
      heading: 'How to activate Amazon Gift Card?',
      text: <div>

        <ul className="list-decimal pl-8 flex flex-col gap-2">
          <li>Launch the Amazon site and log in to your account</li>
          <li>
            From the menu bar at the top of the Amazon window, choose “Account & Lists”, then choose “Account”.
          </li>
          <li>
            Click on the “Gift cards” button, then choose “Redeem a Gift Card”.
          </li>
          <li>
            Enter the code you have received and click on the button “Apply to your balance”.
          </li>
        </ul>
      </div>,
    },
    {
      heading: 'How to activate Xbox Live Gift Card?',
      text: <div>
        <ul className="list-decimal pl-8 flex flex-col gap-2">
          <li>Open the Microsoft log in website - <a className="text-underline text-green-500" href="https://login.live.com" target="_blank">https://login.live.com</a>
          </li>
          <li>
            Log into your Microsoft account.
          </li>
          <li>
            Click on the “Gift cards” button, then choose “Redeem a Gift Card”.
          </li>
          <li>
            Go to <a className="text-underline text-green-500" href="https://redeem.microsoft.com" target="_blank">https://redeem.microsoft.com</a>
          </li>
          <li>
            Enter the code you have received and click 'Next'.
          </li>
          <li>
            Click on the confirmation button. <br /> The product has been added to your account. Enjoy! <br /> For other activation methods, <a className="text-underline text-green-500" href="https://support.xbox.com/en-US/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes" target="_blank">open link</a>.
            <br />
            What Countries/Regions are supported by Xbox? <br/>
            Ever expanding list can be found here: <br />
            <a className="text-underline text-green-500" href="https://www.xbox.com/en-GB/regions" target="_blank">https://www.xbox.com/en-GB/regions</a>
          </li>
        </ul>
      </div>,
    },
  ];

  return (
    <div id="faq">
      <Container className="mx-auto w-full max-w-3xl text-white">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-center">
            <span className="text-4xl capitalize text-white">FAQ</span>
          </div>

          {items.map((item, key) => (
            <div
              onClick={() => {
                if (active === key) {
                  setActive(-1);
                } else {
                  setActive(key);
                }
              }}
              key={key}
              className={cn(
                "bg-surface-200 cursor-pointer overflow-hidden px-5 transition",
                active !== key && "h-[64px]",
                active === key && "h-[fit]"
              )}
            >
              <div className="flex h-[64px] items-center justify-between">
                <div
                  className={cn(
                    "text-xl capitalize leading-[30px] transition",
                    active !== key && "text-secondary-300",
                    active === key && "text-white"
                  )}
                >
                  {item.heading}
                </div>
                <div className="text-primary-50 text-2xl opacity-40">
                  {active === key ? <FaMinus /> : <FaPlus />}
                </div>
              </div>

              <div className="pb-4">
                <div className="bg-surface-300 mb-4 h-[1px] w-full" />
                <div className="text-secondary-300 font-secondary text-lg leading-7">
                  {item.text}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
